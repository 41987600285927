import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user-service';
import OktaAuth from '@okta/okta-auth-js';
import {switchMap} from 'rxjs/operators';
import {ThemeService} from '../../../services/theme.service';

@Component({
  selector: 'app-login-okta',
  templateUrl: './login-okta.component.html',
  styleUrl: './login-okta.component.scss'
})
export class LoginOktaComponent {
  formGroup: FormGroup;
  theme$ = this.themeService.getTheme();
  oktaButtonLightPath = '../../../assets/okta_signin_light.svg';
  oktaButtonDarkPath = '../../../assets/okta_signin_dark.svg';
  @Output() toggleSigInClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder,
              private readonly userService: UserService,
              private readonly themeService: ThemeService) {
    this.formGroup = formBuilder.group({
      email: ['', [Validators.required]],
    });
  }

  oktaLogin() {
    const email = this.formGroup.controls['email'].value;
    const parts = email.split('@');
    const redirectUri = `${window.location.origin}/okta/callback`;
    this.userService.getOktaDomain(parts[parts.length - 1]).pipe(
      switchMap(response => {
        const issuer = `${response.domain}/oauth2/${response.serverId}`;
        localStorage.setItem('okta-client-id', response.clientId);
        localStorage.setItem('okta-issuer', issuer);
        const oktaAuth = new OktaAuth({
          clientId: response.clientId,
          issuer: issuer,
          redirectUri: redirectUri,
        });
        return oktaAuth.signInWithRedirect({
          loginHint: email,
        });
      })
    ).subscribe({
      next: () => console.log('next'),
      error: console.log,
      complete: () => console.log('complete'),
    });
  }

  toggleSigIn() {
    this.toggleSigInClick.emit();
  }
}
