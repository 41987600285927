import { Component } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-batch-update-custom-dialog',
  templateUrl: './batch-update-custom-dialog.component.html',
  styleUrl: './batch-update-custom-dialog.component.scss'
})
export class BatchUpdateCustomDialogComponent {
  form: FormGroup;

  constructor(private readonly formBuilder: FormBuilder,
              private readonly dialogRef: MatDialogRef<BatchUpdateCustomDialogComponent>) {
    this.form = this.formBuilder.group({
      update1: [false],
      custom1: [{value: '', disabled: true}],
      update2: [false],
      custom2: [{value: '', disabled: true}],
    })
  }

  onSubmit() {
    this.dialogRef.close(this.form.value);
  }

  onCancel() {
    this.dialogRef.close();
  }

  get noneSelected() {
    return !this.form.value.update1 && !this.form.value.update2;
  }

  update1(event) {
    if (event.checked) {
      this.form.controls.custom1.enable({onlySelf: true});
    } else {
      this.form.controls.custom1.disable({onlySelf: true});
    }
  }

  update2(event) {
    if (event.checked) {
      this.form.controls.custom2.enable({onlySelf: true});
    } else {
      this.form.controls.custom2.disable({onlySelf: true});
    }
  }
}
