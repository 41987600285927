import {ErrorHandler, Injectable} from '@angular/core';
import {ConfigurationService} from '../services/configuration-service';
import * as Sentry from '@sentry/browser';
import {from} from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class CtmcErrorHandler implements ErrorHandler {
  constructor(private readonly configurationService: ConfigurationService) {
    from(configurationService.load()).subscribe(config => {
      Sentry.init({
        dsn: config.sentryDsn,
        environment: config.sentryEnvironment.toLowerCase(),
      });
    });
  }

  handleError(error: any) {
    Sentry.captureException(error);
    environment.__DEV__ &&  console.error(error);
  }
}
