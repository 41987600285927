
<div *ngIf="results$ | async as results">

    @if (Object.keys(results.bitlockerRecoveryKeys).length > 0) {
        <mat-list>
            <div mat-card-title>{{'APP_RECOVERY_KEYS' | translate}}</div>
            @for (endpointName of Object.keys(results.bitlockerRecoveryKeys); track endpointName) {
                <mat-list>
                    <mat-list-item>
                        <span matListItemTitle>{{ endpointName }}</span>
                        @for (keyResult of results.bitlockerRecoveryKeys[endpointName]; track keyResult.keyId) {
                            <span matListItemLine>
                                <span class="mdi mdi-key"></span>
                                <a [routerLink]="['/device', keyResult.licenseUuid]" [queryParams]="{'tab': 'bitlocker'}">{{keyResult.keyId}}</a>
                            </span>
                        }
                    </mat-list-item>
                </mat-list>
            }
        </mat-list>
    }

    @if (results.endpoints.length > 0) {
        <mat-list>
            <div mat-subheader>{{'APP_ENDPOINTS' | translate}}</div>
            <mat-list-item *ngFor="let el of results.endpoints">
                <span class="mdi mdi-monitor"></span>
                <a [routerLink]="['/device', el.id]">{{el.name}}</a>
            </mat-list-item>
        </mat-list>
    }

    @if (user$ | async; as user) {
        @if (user.role == UserRole.ADMIN) {
            <mat-list *ngIf="results.endpointGroups.length > 0">
                <div mat-subheader>{{'APP_ENDPOINT_GROUPS' | translate}}</div>
                <mat-list-item *ngFor="let el of results.endpointGroups">
                    <span class="mdi mdi-format-list-bulleted"></span>
                    <a [routerLink]="['/group-details', 'device', el.id]">{{el.name}}</a>
                </mat-list-item>
            </mat-list>

            <mat-list *ngIf="results.userGroups.length > 0">
                <div mat-subheader>{{'APP_USER_GROUPS' | translate}}</div>
                <mat-list-item *ngFor="let el of results.userGroups">
                    <span class="mdi mdi-format-list-bulleted"></span>
                    <a [routerLink]="['/group-details', 'user', el.id]">{{el.name}}</a>
                </mat-list-item>
            </mat-list>

            <mat-list *ngIf="results.policies.length > 0">
                <div mat-subheader>{{'APP_POLICIES' | translate}}</div>
                <mat-list-item *ngFor="let el of results.policies">
                    <span class="mdi mdi-wallet-membership"></span>
                    <a [routerLink]="['/policy', el.id.toString()]">{{el.name}}</a>
                </mat-list-item>
            </mat-list>
        }
    }

    <mat-list *ngIf="results.users.length > 0">
        <div mat-subheader>{{'APP_USERS' | translate}}</div>
        <mat-list-item *ngFor="let el of results.users">
            <span class="mdi mdi-account"></span>
            <a [routerLink]="['/users']" [queryParams]="{'search': el.name}">{{el.name}}</a>
        </mat-list-item>
    </mat-list>

</div>
