<div [ngClass]="{'ctmc-search-open-backdrop': showSearch}" (click)="hideSearchAll()"></div>
<div class="ctmc-search" [ngClass]="{'ctmc-search-open': showSearch}" tourAnchor="search-all-results">
    <form autocomplete="off">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" tourAnchor="search-all-button">
            <input matInput type="text" #searchInput placeholder="{{'DSS_SEARCH' | translate}}"
                   (focus)="showSearchAll()"
                   (keydown.escape)="clearAndHide(searchInput, $event)" name="search" autocomplete="off">
            <button matSuffix mat-icon-button *ngIf="searchInput.value.length > 0"
                    (click)="clearAndHide(searchInput, $event)" tourAnchor="search-all-clear">
                <i class="mdi mdi-close close-icon"></i>
            </button>
            <button matSuffix mat-icon-button>
                <i class="mdi mdi-magnify"></i>
            </button>
        </mat-form-field>
    </form>
    <app-search-recent *ngIf="showSearch && searchInput.value.length < 3; else haveSearchTerm"
                       (click)="hideSearchAll()"></app-search-recent>
    <ng-template #haveSearchTerm>
        <app-search-results *ngIf="searchInput.value.length >= 3" [term]="searchInput.value"
                            (click)="hideSearchAll()"></app-search-results>
    </ng-template>
</div>
