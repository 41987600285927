import { Component, inject } from '@angular/core';
import { TourService } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-search-all',
  templateUrl: './search-all.component.html',
  styleUrls: ['./search-all.component.scss']
})
export class SearchAllComponent {
  private readonly tourService = inject(TourService);
  showSearch = false;

  showSearchAll() {
    this.showSearch = true;
  }

  hideSearchAll() {
    this.showSearch = false;
  }

  clearAndHide(input: HTMLInputElement, event: Event) {
    this.tourService.next();
    input.value = "";
    input.blur();
    this.showSearch = false;
    event.stopPropagation();
  }
}
