
<h2 mat-dialog-title>{{'assign-licenses.batch-update-dialog.title' | translate}}</h2>

<mat-dialog-content>
    <form [formGroup]="form">
        <div class="form-row">
            <mat-checkbox formControlName="update1" (change)="update1($event)"></mat-checkbox>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'assign.columns.custom-1' | translate }}</mat-label>
                <input matInput type="text" formControlName="custom1">
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-checkbox formControlName="update2" (change)="update2($event)"></mat-checkbox>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'assign.columns.custom-2' | translate }}</mat-label>
                <input matInput type="text" formControlName="custom2">
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<div mat-dialog-actions align="end" class="button-wrapper">
    <button mat-raised-button (click)="onCancel()" type="button" color="primary">{{'CANCEL' | translate}}</button>
    <button mat-raised-button (click)="onSubmit()" [disabled]="noneSelected" type="button" color="primary">{{'OK' | translate}}</button>
</div>
