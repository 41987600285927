@if (restConfig$ | async; as restConfig) {
    <div class="group-details-container">
        <div class="container-header">
            <div class="buttons">
                <button mat-raised-button color="primary" aria-label="Cancel" (click)="cancelButtonClick()">{{'CANCEL' | translate}}</button>
                <button mat-raised-button
                        color="primary"
                        class="save-button"
                        aria-label="Save Group"
                        (click)="saveGroupButtonClick()"
                        [disabled]="saving">
                    @if (saving) {
                        <span class="mdi mdi-dots-circle mdi-spin group-details-save-spinner"></span>
                        <span>{{'groups.saving' | translate}}</span>
                    } @else {
                        <span>{{'SAVE' | translate}}</span>
                    }
                </button>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>{{'groups.type' | translate}}</mat-label>
                <mat-select [(ngModel)]="groupType" [disabled]="!restConfig.shared_key_ui || !!groupId" #detailType>
                    <mat-option [value]="GroupType.user">{{'groups.types.user' | translate}}</mat-option>
                    <mat-option [value]="GroupType.device">{{'groups.types.device' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="child-details-container">
            @if (loaded$ | async) {
                @switch (detailType.value) {
                    @case (GroupType.device) {
                        <app-group-details-device
                                [groupNameValidator]="groupNameValidator"
                                [(groupSettings)]="groupSettings"></app-group-details-device>
                    }
                    @case (GroupType.user) {
                        <app-group-details-user
                                [groupNameValidator]="groupNameValidator"
                                [(groupSettings)]="groupSettings"></app-group-details-user>
                    }
                }
            }
        </div>
    </div>
}
