import {Component, DestroyRef, inject} from '@angular/core';
import {AlertDialogComponent} from '../../../dialogs/alert/alert.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {combineLatest, EMPTY, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {HeaderService} from '../../../header.service';
import {D3eService} from '../../../../services/d3e-service';
import {IIntegration, PartnerTypes} from '../../../../types/integrations';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ThemeService} from '../../../../services/theme.service';
import {UserRole} from '../../../../types/user';

@Component({
  selector: 'app-okta',
  templateUrl: './okta.component.html',
  styleUrl: './okta.component.scss'
})
export class OktaComponent {
  formGroup: FormGroup;
  integrationId$: Observable<string> = this.route.params.pipe(map(params => params['id']));
  private destroyRef = inject(DestroyRef);
  integration: IIntegration;
  theme$ = this.themeService.getTheme();

  constructor(
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private headerService: HeaderService,
    private d3eService: D3eService,
    private route: ActivatedRoute,
    private readonly themeService: ThemeService,
    formBuilder: FormBuilder,
  ) {
    this.formGroup = formBuilder.group({
      domain: ['', Validators.required],
      clientId: ['', Validators.required],
      serverId: ['default', Validators.required],
      role: [UserRole.USER],
    });
    combineLatest([this.integrationId$, this.headerService.orgSelectedChanged]).pipe(
      switchMap(([integrationId, selectedOrgId]) => {
        if (integrationId != 'new') {
          return this.d3eService.get_integration(integrationId, selectedOrgId != 'All' ? selectedOrgId : undefined);
        } else {
          return EMPTY;
        }
      }),
    ).subscribe(integration => {
      this.integration = integration;
      this.formGroup.controls['clientId'].setValue(integration.config.oktaClientId)
      this.formGroup.controls['domain'].setValue(integration.config.oktaDomain)
      this.formGroup.controls['serverId'].setValue(integration.config.oktaServerId)
      this.formGroup.controls['role'].setValue(integration.config.oktaDefaultRole)
    });
  }
  goToIntegrations() {
    this.router.navigateByUrl('/integrations');
  }

  cancel() {
    if (this.formGroup.touched) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          title: this.translate.instant('DISCARD_CHANGES'),
          content: this.translate.instant('DISCARD_CHANGES_CONTENT'),
          rejectBtnName: this.translate.instant('NO'),
          acceptBtnName: this.translate.instant('YES'),
          showRejectBtn: true,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.goToIntegrations();
        }
      });
    } else {
      this.goToIntegrations();
    }
  }

  save() {
    combineLatest([
      this.integrationId$,
      this.headerService.orgSelectedChanged,
    ]).pipe(
      switchMap(([integrationId, selectedOrgId]) => {
        if (integrationId == 'new') {
          const integration: IIntegration = {
            display_name: 'Okta',
            enabled: true,
            partner_id: PartnerTypes.OKTA,
            config: {
              oktaClientId: this.formGroup.value.clientId,
              oktaDomain: this.formGroup.value.domain,
              oktaServerId: this.formGroup.value.serverId,
              oktaDefaultRole: this.formGroup.value.role,
            }
          };
          return this.d3eService.add_integration(integration, selectedOrgId != 'All' ? selectedOrgId : undefined);
        } else {
          this.integration.config = {
            oktaClientId: this.formGroup.value.clientId,
            oktaDomain: this.formGroup.value.domain,
            oktaServerId: this.formGroup.value.serverId,
            oktaDefaultRole: this.formGroup.value.role,
          }
          return this.d3eService.update_integration(this.integration, selectedOrgId != 'All' ? selectedOrgId : undefined);
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => this.goToIntegrations());
  }

  protected readonly UserRole = UserRole;
}
