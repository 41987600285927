import {Component} from '@angular/core';
import OktaAuth from '@okta/okta-auth-js';
import {from} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {UserService} from '../../../services/user-service';
import {LoginStatus} from '../../../types/user';
import {AuthService} from '../../../services/auth-service';
import {LOGIN_DEVICE_TOKEN} from '../login/login.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-okta-callback',
  templateUrl: './okta-callback.component.html',
  styleUrl: './okta-callback.component.scss'
})
export class OktaCallbackComponent {

  constructor(private readonly userService: UserService,
              private readonly authService: AuthService,
              private readonly router: Router) {
    const clientId = localStorage.getItem('okta-client-id');
    const issuer = localStorage.getItem('okta-issuer');
    const deviceToken = sessionStorage.getItem(LOGIN_DEVICE_TOKEN);
    const oktaAuth = new OktaAuth({
      clientId: clientId,
      issuer: issuer,
      redirectUri: window.location.origin + '/okta/callback',
    });
    from(oktaAuth.token.parseFromUrl()).pipe(
      switchMap(tokens => this.userService.oktaUserLogin(tokens, deviceToken, false))
    ).subscribe({
      next: response => {
        if (response.status === LoginStatus.SUCCESS) {
          if (!!deviceToken) {
            this.router.navigate(['/login-success']);
          } else {
            this.authService.login(response.token, true, undefined);
          }
        }
      },
      error: v => console.error('error:', v),
    });
  }

}
