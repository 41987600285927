import { Component } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {catchError, map, switchMap} from 'rxjs/operators';
import {SecurityRequestService} from '../../../services/security-request.service';
import {CigentPoliciesURL, CigentTermsURL, LenovoPrivacyURL, Partners} from '../../../types/partners';
import {of} from 'rxjs';
import {SecurityRequestResult} from '../../../types/security-requests';

enum VoteStatus {
  SUCCESS = 'success',
  ALREADY_APPROVED = 'already-approved',
  ALREADY_VOTED = 'already-voted',
  NOT_PENDING = 'not-pending',
  RECOVERY_KEY_DISABLED = 'recovery-key-disabled',
  ERROR = 'error',

}

@Component({
  selector: 'app-update-request',
  templateUrl: './update-request.component.html',
  styleUrl: './update-request.component.scss'
})
export class UpdateRequestComponent {

  vote!: SecurityRequestResult;

  cigentPartner = Partners.Cigent;
  doqubizPartner = Partners.Doqubiz;
  digistorPartner = Partners.Digistor;
  kanguruPartner = Partners.Kanguru;
  lenovoPartner = Partners.Lenovo;
  vrsPartner = Partners.VRS;
  partnerId: Partners = Partners.Cigent;

  result$ = this.route.queryParams.pipe(
    switchMap(params => {
      this.vote = params.result;
      return this.securityRequestService.postSecurityStatusByToken(params.token, params.result);
    }),
    map(response => {
      if (response.success) {
        return VoteStatus.SUCCESS;
      }
      if (response.notPending) {
        return VoteStatus.NOT_PENDING;
      }
      if (response.alreadyApproved) {
        return VoteStatus.ALREADY_APPROVED;
      }
      if (response.alreadyVoted) {
        return VoteStatus.ALREADY_VOTED;
      }
      if (response.recoveryKeyDisabled) {
        return VoteStatus.RECOVERY_KEY_DISABLED;
      }
      console.log(response);
      return VoteStatus.ERROR;
    }),
    catchError(error => {
      console.error(error);
      return of(VoteStatus.ERROR);
    }),
  );

  constructor(private route: ActivatedRoute,
              private securityRequestService: SecurityRequestService) {}

  protected readonly CigentPoliciesURL = CigentPoliciesURL;
  protected readonly LenovoPrivacyURL = LenovoPrivacyURL;
  protected readonly CigentTermsURL = CigentTermsURL;
  protected readonly VoteStatus = VoteStatus;
}
