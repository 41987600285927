<mat-toolbar class="toolbar mat-elevation-z0">
    <mat-toolbar-row class="title">
        <div *ngIf="partnerId === cigentPartner || partnerId === doqubizPartner || partnerId === digistorPartner"
             class="img-banner">
            <img class="cigent-logo" src="assets/Cigent_Logo_White_Text.svg" alt="Cigent Logo" />
            <img class="cigent-box" src="assets/cigent-box.jpg"  alt="Digital art" />
        </div>
        <div *ngIf="partnerId === lenovoPartner" class="img-banner">
            <div class="lenovo-logo-wrapper">
                <span class="lenovo-logo"></span>
                <div>
                <span class="data-defense">
                  Data Defense <span class="powered-by-cigent">powered by Cigent</span>
                </span>
                </div>
            </div>
            <img class="cigent-box" src="assets/cigent-box.jpg" alt="Digital art" />
        </div>
        <div *ngIf="partnerId === kanguruPartner" class="img-banner">
            <div class="kanguru-logo-wrapper">
                <span class="kanguru-logo"></span>
                <div>
                <span class="data-defense"><span class="powered-by-cigent">Powered by Cigent</span>
                </span>
                </div>
            </div>
            <img class="cigent-box" src="assets/cigent-box.jpg" alt="Digital art" />
        </div>
        <div *ngIf="partnerId === vrsPartner" class="img-banner">
            <div class="vrs-logo-wrapper">
                <span class="vrs-logo"></span>
                <div>
                <span class="data-defense"><span class="powered-by-cigent">Powered by Cigent</span>
                </span>
                </div>
            </div>
            <img class="cigent-box" src="assets/cigent-box.jpg" alt="Digital art" />
        </div>
    </mat-toolbar-row>
</mat-toolbar>

<mat-card appearance="raised" class="form-container mat-elevation-z1">
    <mat-card-content>
        @if (result$ | async; as result) {
            @switch (result) {
                @case (VoteStatus.SUCCESS) {
                    {{ 'update-request.success' | translate }}
                }
                @case (VoteStatus.ALREADY_APPROVED) {
                    {{ 'update-request.already-approved' | translate }}
                }
                @case (VoteStatus.ALREADY_VOTED) {
                    {{ 'update-request.already-voted' | translate }}
                }
                @case (VoteStatus.NOT_PENDING) {
                    {{ 'update-request.not-pending' | translate }}
                }
                @case (VoteStatus.ERROR) {
                    {{ 'update-request.error' | translate }}
                }
            }
        }
    </mat-card-content>
</mat-card>

<div class="footer">
    <div>
          <span *ngIf="partnerId === lenovoPartner; else cigentCopyright">
            <a href="{{LenovoPrivacyURL}}" rel="noopener noreferrer" target="_blank">Lenovo Privacy Statement</a>
          </span>
        <ng-template #cigentCopyright>
            <span>&copy; 2024 Cigent Technology Inc.</span>
        </ng-template>
    </div>
    <div>{{'ADO_EMAIL' | translate}} <a href="mailto:support@cigent.com" target="_blank" rel="noopener noreferrer">support&#64;cigent.com</a> {{'OR_VISIT_TEXT' | translate}} <a href="https://support.cigent.com/support/home" target="_blank" rel="noopener noreferrer" title="Cigent Support">{{'CIGENT_SUPPORT_LINK_TEXT' | translate}}</a></div>
    <div>
          <span *ngIf="partnerId === lenovoPartner; else cigentTermsAndPolicies">
            <a href="{{CigentPoliciesURL}}" rel="noopener noreferrer" target="_blank">Cigent Privacy Policies</a>
          </span>
        <ng-template #cigentTermsAndPolicies>
            <span>Cigent's <a href="{{CigentTermsURL}}" rel="noopener noreferrer" target="_blank">{{'SUBF_TERMS' | translate}}</a> {{'SUBF_AND' | translate}} <a href="https://www.cigent.com/policies" rel="noopener noreferrer" target="_blank">{{'DIV_TOUD' | translate}}</a></span>
        </ng-template>
    </div>
</div>
