<div class="logo-container">
    <div [ngClass]="{'okta-logo-light': (theme$ | async) === 'light', 'okta-logo-dark': (theme$ | async) === 'dark'}" alt="Okta Logo">
        <span class="logo"></span>
    </div>
</div>

<form [formGroup]="formGroup">

    <div class="form-container">
        <div>
            <div>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'okta-domain' | translate }}</mat-label>
                    <input matInput name="policy_severity" formControlName="domain" placeholder="">
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'okta-client-id' | translate }}</mat-label>
                    <input matInput name="policy_severity" formControlName="clientId" placeholder="">
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'okta-server-id' | translate }}</mat-label>
                    <input matInput name="policy_severity" formControlName="serverId" placeholder="">
                </mat-form-field>
            </div>
        </div>

        <div class="role-container">
            <label for="admin-radio" id="admin-radio-label">{{'okta-default-role-label' | translate}}</label>
            <mat-radio-group formControlName="role" id="admin-radio" aria-labelledby="admin-radio-label" color="primary">
                <div>
                    <mat-radio-button id="adminRadio" value="{{UserRole.ADMIN}}" color="primary">{{'APU_ADMIN' | translate}}</mat-radio-button>
                </div>
                <div>
                    <mat-radio-button id="userRadio"  value="{{UserRole.USER}}" color="primary">{{'APU_USER' | translate}}</mat-radio-button>
                </div>
                <div>
                    <mat-radio-button id="helpdeskRadio"  value="{{UserRole.HELP_DESK}}" color="primary">{{'APU_HELPDESK' | translate}}</mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
    </div>

    <button type="button" (click)="cancel()" mat-raised-button>{{ 'CANCEL' | translate }}</button>
    <button type="button" color="primary" (click)="save()" [disabled]="formGroup.invalid" mat-raised-button>{{ 'SAVE' | translate }}</button>

</form>
